import Popup from "reactjs-popup";

export default function RequestReferralModal(props) {

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    // props.setOpenModal(false);
  }

  return (
    <Popup open={true} closeOnDocumentClick={false}>
      <div className="fixed bg-gray-100 flex flex-col justify-start items-start gap-10 p-8 rounded-3xl shadow-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                        2xl:w-[35%] 2xl:h-[60%] xl:w-[40%] xl:h-[55%] lg:w-[45%] lg:h-[55%] md:w-[50%] md:h-[55%] sm:w-[40%] h-[70%] w-[90%] border-2 overflow-auto">
        <button className="absolute top-2 right-4 text-xl border-2 h-8 w-8 font-bold text-black border-black rounded-full text-center" onClick={() => { }}>X</button>
        <div className="w-fit text-center">
          <h1 className="2xl:text-3xl xl:text-2xl lg:text-2xl md:text-2xl text-xl font-bold text-blue-500">Request a Referral to {props.name || "Company Name"}</h1>
          <p className="text-xs">Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium magnam architecto veniam optio tempore nesciunt tenetur inventore ratione quo est perferendis possimus dicta, beatae voluptates nemo aspernatur cum illo iusto.</p>
        </div>

        <div className="w-full h-full">
          <form className="w-full 2xl:text-lg xl:text-base">
            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="" className="font-bold ml-1">Job URL</label>
                <input type="text" placeholder="Job Url" className="w-full p-2 m-0 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black" />
              </div>
              <div>
                <label htmlFor="" className="font-bold ml-1">Description</label>
                <input type="text" placeholder="Description" className="w-full p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black" />
              </div>
              <div>
                <label htmlFor="" className="font-bold ml-1">Job Title</label>
                <textarea type="text" placeholder="Job Title" className="w-full resize-none p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black" />
              </div>
              <button className="w-full bg-blue-500 text-white font-semibold py-2 rounded-3xl hover:bg-blue-600 transition-all duration-200" onClick={handleFormSubmit}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
}