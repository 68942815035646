import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <div className="footer bg-gray-900 text-white p-7 flex flex-col sm:flex-row justify-between items-center sm:items-start">
      
      <div className="heading mb-4 sm:mb-0">
        <p className="font-semibold text-center sm:text-left text-2xl">GDG Pune</p>
      </div>

      <div className="footer-menu flex flex-col sm:flex-row list-none space-y-3 sm:space-x-5 sm:space-y-0 text-l text-center sm:text-left pt-1 sm:ml-0 ">
        <li>About GDG program</li>
        <li>About WTM program</li>
        <li>FAQ</li>
        <li>Code of conduct</li>
        <li>Community Guidelines</li>
      </div>

      <div className="footer-icons flex space-x-5 mt-4 sm:mt-0 pt-1">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '20px' }} />
        </a>

        <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '20px' }} />
        </a>

        <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '20px' }} />
        </a>

        <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '20px' }} />
        </a>
      </div>
    </div>
  );
}
