import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQSection = () => {
  const [expanded, setExpanded] = useState(null);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="text-center p-6 md:p-12 bg-[#F8FAFC]">
      <div className="flex items-center justify-center space-x-3">
        {/* Left SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/LeftVector.svg`}
          alt="Left Vector"
          className="w-8 h-auto"
        />

        {/* Heading Text */}
        <h2
          className="text-black text-3xl md:text-4xl font-bold"
          style={{
            fontFamily: 'Google Sans, sans-serif',
            lineHeight: '1.2',
          }}
        >
          Frequently Asked Questions
        </h2>

        {/* Right SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/RightVector.svg`}
          alt="Right Vector"
          className="w-8 h-auto"
        />
      </div>

      <p className="text-gray-500 mt-2 text-lg">Need Answers? Everything you need to know</p>

      {/* FAQ items*/}
      <div className="mt-6 space-y-0 max-w-3xl mx-auto">
        {/* FAQ Item 1 */}
        <div
          className={`rounded-ss-2xl rounded-se-2xl border border-gray-300 shadow-md ${expanded === 1 ? 'bg-yellow2' : 'bg-[#F0F0F0]'}`}
        >
          <div
            className="flex justify-between items-center px-4 py-3 cursor-pointer"
            onClick={() => handleToggle(1)}
          >
            <h3 className="text-left text-lg md:text-xl font-semibold text-gray-900">
              How can I request a referral as a candidate?
            </h3>
            <span className="text-gray-700">
              {expanded === 1 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {expanded === 1 && (
            <div className="bg-white text-left px-4 py-4 text-gray-700">
              <p className="text-lg leading-relaxed">
                As a candidate, you can request a referral by visiting the referral portal and selecting the companies you're interested in. Once you submit your request, you will be notified when a referer selects you for a referral.
              </p>
            </div>
          )}
        </div>

        {/* FAQ Item 2 */}
        <div
          className={`border border-gray-300 shadow-md ${expanded === 2 ? 'bg-yellow2' : 'bg-[#F0F0F0]'}`}
        >
          <div
            className="flex justify-between items-center px-4 py-3 cursor-pointer"
            onClick={() => handleToggle(2)}
          >
            <h3 className="text-left text-lg md:text-xl font-semibold text-gray-900">
              How do I accept a candidate's referral request as a referer?
            </h3>
            <span className="text-gray-700">
              {expanded === 2 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {expanded === 2 && (
            <div className="bg-white text-left px-4 py-4 text-gray-700 ">
              <p className="text-lg leading-relaxed">
                As a referer, you can see a list of all candidates requesting referrals from your company. You can review their profiles and choose to accept any of them by clicking the "Accept" button next to their name.
              </p>
            </div>
          )}
        </div>

        {/* FAQ Item 3 */}
        <div
          className={`border border-gray-300 shadow-md ${expanded === 3 ? 'bg-yellow2' : 'bg-[#F0F0F0]'}`}
        >
          <div
            className="flex justify-between items-center px-4 py-3 cursor-pointer"
            onClick={() => handleToggle(3)}
          >
            <h3 className="text-left text-lg md:text-xl font-semibold text-gray-900">
              Will I be notified when a referer accepts my referral request?
            </h3>
            <span className="text-gray-700">
              {expanded === 3 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {expanded === 3 && (
            <div className="bg-white text-left px-4 py-4 text-gray-700 ">
              <p className="text-lg leading-relaxed">
                Yes, as a candidate, you will be notified when a referer selects you for a referral. You will receive an email or a notification on the portal to inform you of the status.
              </p>
            </div>
          )}
        </div>

        {/* FAQ Item 4 */}
        <div
          className={`rounded-ee-2xl rounded-es-2xl border border-gray-300 shadow-md ${expanded === 4 ? 'bg-yellow2' : 'bg-[#F0F0F0]'}`}
        >
          <div
            className="flex justify-between items-center px-4 py-3 cursor-pointer"
            onClick={() => handleToggle(4)}
          >
            <h3 className="text-left text-lg md:text-xl font-semibold text-gray-900">
              Can I cancel my referral request once submitted?
            </h3>
            <span className="text-gray-700">
              {expanded === 4 ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </div>
          {expanded === 4 && (
            <div className="bg-white text-left px-4 py-4 text-gray-700 rounded-ee-2xl rounded-es-2xl">
              <p className="text-lg leading-relaxed">
                Yes, as a candidate, you can cancel your referral request at any time before it is accepted by a referer. Simply visit your referral dashboard and click "Cancel" next to the pending request.
              </p>
            </div>
          )}
        </div>
      </div>

      </div>
  );
};

export default FAQSection;
