import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import LandingPage from './routes/LandingPage';
import Error404 from './routes/Error404';
import Navbar from './components/global/Navbar';
import Footer from './components/global/Footer';
import { Provider } from 'react-redux';
import store from './store/store';
import FAQSection from './components/global/FAQSection';
import CandidateProfilePage from './components/global/CandidateProfilePage';
import RequestReferralModal from './components/global/RequestReferralModal';
import CompaniesList from './components/global/CompaniesList';
import Dashboard from './components/candidate/Dashboard';


const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },{
    path: "/faq",
    element: <FAQSection />,
  },
  {
    path: "/candidate/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/candidateprofilepage",
    element: <CandidateProfilePage />,
  },

  {
    path: "/companiesList",
    element: <CompaniesList />,
  },
  // new paths will go here
  {
    path: "*",
    element: <Error404 />
  },
  {
    path:"/popup",
    element: <RequestReferralModal />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
   <React.StrictMode>
    <Navbar/>
      <RouterProvider router={router} />
      <Footer />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
