import React, { useState } from "react";

const HiwSection = () => {
  return (
    <div className="pb-10">
      <div className="flex items-center justify-center space-x-3">
        {/* Left SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/LeftVector.svg`}
          alt="Left Vector"
          className="w-8 h-auto"
        />

        {/* Heading Text */}
        <h2
          className="text-black text-3xl md:text-4xl font-bold"
          style={{
            fontFamily: "Google Sans, sans-serif",
            lineHeight: "1.2",
          }}
        >
          How it work
        </h2>

        {/* Right SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/RightVector.svg`}
          alt="Right Vector"
          className="w-8 h-auto"
        />
      </div>

      <div className="flex items-center justify-center pt-20 ">
        {/* Main Container */}
        <div className="flex items-center w-full gap-8">
          {/* Left Section - Image */}
          <div className="relative w-1/3 flex justify-center">
            <div className="w-40 h-40 bg-gray-100 rounded-full flex items-center justify-center">
              <img
                src="lp-v1.svg"
                alt="Profile Illustration"
                className="w-32 h-32 object-contain"
              />
            </div>
          </div>

          {/* Right Section - Content */}
          <div className="w-2/3">
            {/* Title */}
            <h1 className="text-2xl font-bold text-black font-sans mb-4">
              Create and Share Your Profile
            </h1>

            <ul className="space-y-4 text-gray-500 text-base font-normal">
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                Sign up on the platform and build a profile showcasing your
                skills, experience, and desired roles.
              </li>
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                Browse job openings in companies where referrals can increase
                your chances, and apply to roles that fit your expertise.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          src="lp-v4.svg"
          alt="Divider"
          className="flex items-center w-auto h-[12.5rem]"
        />
      </div>
      <div className="flex items-center justify-center">
        {/* Main Container */}
        <div className="flex items-center w-full gap-8">
          {/* Right Section - Content */}
          <div className="w-2/3">
            {/* Title */}
            <h1 className="text-2xl font-bold text-black font-sans mb-4">
              Get Connected with Referrers
            </h1>

            <ul className="space-y-4 text-gray-500 text-base font-normal">
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                After you apply, your profile becomes visible to company
                employees who are registered as Referrers on our platform.
              </li>
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                These employees can see the details you’ve provided and assess
                if your profile matches their company’s needs.
              </li>
            </ul>
          </div>

          {/* Left Section - Image */}
          <div className="relative w-1/3 flex justify-center">
            <div className="w-40 h-40 bg-gray-100 rounded-full flex items-center justify-center">
              <img
                src="lp-v2.svg"
                alt="Profile Illustration"
                className="w-32 h-32 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img
          src="lp-v5.svg"
          alt="Divider"
          className="flex items-center w-auto h-[12.5rem]"
        />
      </div>

      <div className="flex items-center justify-center">
        {/* Main Container */}
        <div className="flex items-center w-full gap-8">
          {/* Left Section - Image */}
          <div className="relative w-1/3 flex justify-center">
            <div className="w-40 h-40 bg-gray-100 rounded-full flex items-center justify-center">
              <img
                src="lp-v3.svg"
                alt="Profile Illustration"
                className="w-32 h-32 object-contain"
              />
            </div>
          </div>

          {/* Right Section - Content */}
          <div className="w-2/3">
            {/* Title */}
            <h1 className="text-2xl font-bold text-black font-sans mb-4">
              Increase Your Chances
            </h1>

            <ul className="space-y-4 text-gray-500 text-base font-normal">
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                When a Referrer decides to support your application, they refer
                you for the role, providing you with a powerful recommendation.
              </li>
              <li className="flex items-start gap-2">
                <span className="w-1 h-1 bg-blue rounded-full mt-2 p-[0.160rem]"></span>
                This referral significantly increases your visibility to hiring
                managers and can help fast-track your application.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiwSection;
