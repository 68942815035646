import React from "react";

const Table = ({ data, activeTab }) => {
  const filteredData = data.filter((item) => {
    if (activeTab === 1) return item.status === "Pending";
    if (activeTab === 2) return item.status === "Accepted";
    if (activeTab === 3) return item.status === "Rejected";
    return false;
  });

  return (
    <div className="mt-3 w-3/4 rounded-xl bg-white border border-gray-300">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-4 py-2 font-bold text-md text-left rounded-tl-xl">Company Name</th>
            <th className="px-4 py-2 font-bold text-md text-center rounded-tr-xl">Application View</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id} className="border-t border-gray-300">
              <td className="px-4 py-2">{item.name}</td>
              <td className="px-4 py-2 text-center font-medium">
                <a href={item.jobUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
