import React, { useState } from "react";
import { FaFolder } from "react-icons/fa";
import Popup from "../EmailPopup";


export default function CandidateProfilePage() {


  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSave = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); 
  };

  return (
    
  
    <div className="min-h-screen bg-gray-50 text-gray-700">
      
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />


      {/* Main Content */}
      <div className="container mx-auto w-[90%] px-4 sm:px-0 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:m-8 gap-6">
          {/* About Section */}
          <div className="bg-white shadow-md rounded-2xl p-6 lg:col-span-2">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-blue-600">
              About
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1 required">
                  First Name   <span className=" text-red-500">*</span>  
                </label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1 required">
                  Last Name  <span className=" text-red-500">*</span>  
                </label>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
            
                  <div className="lg:col-span-2">
                    <label className="block text-sm font-medium mb-1 required">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <select
                        className="w-full border rounded-lg px-3 py-2 appearance-none"
                        required
                      >
                        <option value="" disabled selected>
                          Select Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none text-grey-400">
                        ▼
                      </span>
                    </div>
                  </div>

              <div className="sm:col-span-2">
                <label className="block text-sm font-medium mb-1 required">
                  Personal Email Address  <span className=" text-red-500">*</span>  
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1 required">
                  Contact No. <span className=" text-red-500">*</span>  
                </label>
                <input
                  type="tel"
                  placeholder="Enter your contact number"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1 required">Location <span className=" text-red-500">*</span>  </label>
                <input
                  type="text"
                  placeholder="Enter your location"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div className=" lg:col-span-2">
                <label className="block text-sm font-medium mb-1 required">Profession <span className=" text-red-500">*</span>  </label>
                <input
                  type="text"
                  placeholder="Enter your profession"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Current Company
                </label>
                <input
                  type="text"
                  placeholder="Enter your company"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Current Professional Title
                </label>
                <input
                  type="text"
                  placeholder="Enter your title"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
            </div>
          </div>

          {/* Social Profiles Section */}
          <div className=" flex flex-col ">
            <div className="flex flex-col bg-white shadow-md rounded-2xl p-6">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-blue-600">
              Social Profiles
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1 required">LinkedIn <span className=" text-red-500">*</span>  </label>
                <input
                  type="url"
                  placeholder="Enter your LinkedIn profile"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Website</label>
                <input
                  type="url"
                  placeholder="Enter your website"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">GitHub</label>
                <input
                  type="url"
                  placeholder="Enter your GitHub profile"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Twitter</label>
                <input
                  type="url"
                  placeholder="Enter your Twitter profile"
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
            </div> 
          </div>
          <div className=" mt-6 ">
            <div className="flex-col border-2 bg-white shadow-md rounded-2xl border-dashed border-gray-300  p-4 text-center">
                <div className="flex flex-col items-center space-y-2">
                  <FaFolder className="text-yellow-500 w-10 h-10" />
                  <p className="text-gray-700 font-semibold ">Drag & drop a .pdf resume here <span className=" text-red-500">*</span>  </p>
                  <button className="text-blue-500  mt-2 required underline" >
                  Browse                  
                  </button>
                </div>
          </div>
          </div>
       </div>
            
       
         
       

       
        </div>

        {/* Save Button */}
        <div className="mt-6  flex justify-center">
          <button  className="bg-blue-500 w-[95%]  text-white font-semibold px-6 py-2 rounded-lg hover:bg-blue-600 transition"
            onClick={handleSave}s
          >
            Save
          </button>
        </div>
      </div>

    </div>
  );
}


