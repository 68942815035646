import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    authToken: null,
    userData: null,
    isAuthenticated: false,
  },
  reducers: {
    // TODO: extend functionality to handle the login
    login: (state, action) => {
      state.userData = action.payload.userData;
      state.authToken = action.payload.authToken;
      state.isAuthenticated = true;
    },
    //TODO: invalidate auth token by firing api call then clear the user
    logout: (state) => {
      state.userData = null;
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
