import React, { useState } from "react";

const GetReferralBanner = () => {
  return (
    <div className="flex items-center justify-center space-x-3 py-24">
      {/* Left SVG */}
      <img
        src={`${process.env.PUBLIC_URL}/LeftVector2.svg`}
        alt="Left Vector"
        className="w-auto h-52"
      />

      {/* Heading Text */}
      <div className="px-14 text-center">
        <div className="text-black text-md md:text-md font-google-sans-400">
          #Get Referred by <span className="text-blue font-bold">Trusted</span>{" "}
          Insiders
        </div>
        <div className="text-black text-3xl md:text-3xl font-bold my-6">
          We’re making
          <br />
          job <span className="text-yellow">referrals accessible</span>
          <br />
          for everyone
        </div>
        <div className="">
          <button className="w-96 lg:w-[228px] bg-yellow opacity-80 text-black font-semibold px-4 py-2 rounded-full hover:opacity-100 transition-all duration-400 border-[1px] border-black	">
            Get Referrals to your job
          </button>
        </div>
      </div>

      {/* Right SVG */}
      <img
        src={`${process.env.PUBLIC_URL}/RightVector2.svg`}
        alt="Right Vector"
        className="w-auto h-52"
      />
    </div>
  );
};

export default GetReferralBanner;
