import React, { useState } from "react";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <nav className="flex items-center justify-between px-4 py-2 bg-white shadow-md">
        <div className="flex items-center text-blue-600 font-bold ml-4 text-lg">
          <img src='/icon.png' alt="Box" className="w-5 h-5 mr-2" />
          <span>referrals</span>
        </div>

        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        <div className="hidden md:flex space-x-4  text-gray-500">
          <a href="#home" className="text-blue-600 hover:text-blue-800">
            Home
          </a>
          <a href="#about" className="hover:text-blue-800">
            About us
          </a>
          <a href="#contact" className="hover:text-blue-800">
            Contact us
          </a>
        </div>

        <div className="hidden md:flex items-center space-x-3">
          <button className="w-[180px] lg:w-[228px] bg-yellow opacity-80 text-black font-semibold px-4 py-2 rounded-full hover:opacity-100 transition-all duration-400 border-[1px] border-black	">
            Sign in
          </button>
          <button className="w-[180px] lg:w-[228px] bg-blue opacity-80 text-black font-semibold px-4 py-2 rounded-full hover:opacity-100 transition-all duration-200 border-[1px] border-black">
            Sign up
          </button>
          <img
            src='/profile.png'
            alt="Profile"
            className="w-8 h-8 rounded-full border border-gray-200"
          />
        </div>

        <div
          className={`fixed inset-y-0 right-0 w-2/3 bg-white transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out shadow-lg z-50`}
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <div className="flex items-center text-blue-600 font-bold text-lg">
              <img src='/icon.png' alt="icon" className="w-5 h-5 mr-2" />
              referrals
            </div>

            <button onClick={() => setIsOpen(false)} className="text-gray-600">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col px-4 py-6 space-y-4 text-gray-800">
            <a href="#home" className="text-blue-600 hover:text-blue-800">
              Home
            </a>
            <a href="#about" className="hover:text-blue-800">
              About us
            </a>
            <a href="#contact" className="hover:text-blue-800">
              Contact us
            </a>
            <button className="text-left text-blue-600 hover:text-blue-800">
              Sign in
            </button>
            <button className="text-left text-blue-600 hover:text-blue-800">
              Sign up
            </button>
          </div>
        </div>
      </nav>

      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 bg-black opacity-50 z-40"
        ></div>
      )}
    </div>
  );
}
