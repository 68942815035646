import React from "react";

const CompaniesList = () => {
  // Fake API data for companies
  const apiData = [
    {
      id: 1,
      name: "Google",
      description:
        "A multinational technology company specializing in Internet-related services.",
      hq_address: "1600 Amphitheatre Parkway, Mountain View, CA",
      no_of_employees: "156,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "Technology, Internet",
      company_website_url: "https://www.google.com",
      careers_website_url: "https://careers.google.com",
      consent_received: true,
      created_at: "2020-01-01T00:00:00Z",
      updated_at: "2023-01-01T00:00:00Z",
    },
    {
      id: 2,
      name: "Amazon",
      description:
        "A multinational conglomerate focusing on e-commerce and cloud computing.",
      hq_address: "410 Terry Ave N, Seattle, WA",
      no_of_employees: "1,500,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "E-commerce, Cloud Computing",
      company_website_url: "https://www.amazon.com",
      careers_website_url: "https://www.amazon.jobs",
      consent_received: true,
      created_at: "2019-06-01T00:00:00Z",
      updated_at: "2023-05-15T00:00:00Z",
    },
    {
      id: 3,
      name: "Microsoft",
      description:
        "A multinational technology company producing software, hardware, and services.",
      hq_address: "One Microsoft Way, Redmond, WA",
      no_of_employees: "220,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "Technology, Software",
      company_website_url: "https://www.microsoft.com",
      careers_website_url: "https://careers.microsoft.com",
      consent_received: false,
      created_at: "2021-03-10T00:00:00Z",
      updated_at: "2023-04-22T00:00:00Z",
    },
    {
      id: 4,
      name: "Apple",
      description:
        "A technology company that designs, manufactures, and sells consumer electronics.",
      hq_address: "1 Apple Park Way, Cupertino, CA",
      no_of_employees: "164,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "Technology, Consumer Electronics",
      company_website_url: "https://www.apple.com",
      careers_website_url: "https://jobs.apple.com",
      consent_received: true,
      created_at: "2018-09-01T00:00:00Z",
      updated_at: "2023-06-30T00:00:00Z",
    },
    {
      id: 5,
      name: "Tesla",
      description:
        "An automotive and energy company focused on electric vehicles and renewable energy.",
      hq_address: "3500 Deer Creek Road, Palo Alto, CA",
      no_of_employees: "127,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "Automotive, Renewable Energy",
      company_website_url: "https://www.tesla.com",
      careers_website_url: "https://www.tesla.com/careers",
      consent_received: false,
      created_at: "2015-07-01T00:00:00Z",
      updated_at: "2023-08-20T00:00:00Z",
    },
    {
      id: 6,
      name: "Meta",
      description:
        "A social technology company that develops platforms like Facebook and Instagram.",
      hq_address: "1 Hacker Way, Menlo Park, CA",
      no_of_employees: "86,000",
      logo_url: "https://via.placeholder.com/64x64",
      sectors: "Technology, Social Media",
      company_website_url: "https://www.meta.com",
      careers_website_url: "https://www.meta.com/careers",
      consent_received: true,
      created_at: "2016-11-15T00:00:00Z",
      updated_at: "2023-09-10T00:00:00Z",
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen px-4 py-6">
      {/* Search Bar */}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-8">
        <input
          type="text"
          placeholder="Search companies"
          className="w-full sm:w-3/5 p-3 rounded-full border border-gray-300 text-lg text-gray-700 focus:outline-none focus:ring focus:ring-blue-300"
        />
        <button className="w-full sm:w-40 p-3 bg-blue-500 text-white text-lg font-bold rounded-full hover:bg-blue-400 transition">
          Search
        </button>
      </div>

      {/* Companies Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {apiData.map((company) => (
          <div
            key={company.id}
            className="bg-white rounded-2xl shadow-lg border border-gray-200 flex items-center gap-4 p-4"
          >
            {/* Logo */}
            <a
              href={company.company_website_url}
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none focus:ring-2 focus:ring-blue-400 flex-shrink-0"
            >
              <img
                src={company.logo_url}
                className="w-16 h-16 rounded-full object-cover"
              />
            </a>
            {/* Company Details */}
            <div className="flex-grow">
              <h2 className="text-lg font-semibold text-gray-800">
                {company.name}
              </h2>
              <p className="text-sm text-gray-600">{company.sectors}</p>
            </div>
            {/* Apply Button */}
            <button
              onClick={() => console.log("Applied")}
              className="bg-yellow-400 text-gray-800 text-sm font-bold px-4 py-2 rounded-full border border-gray-300 hover:bg-yellow-300 transition flex-shrink-0"
            >
              Apply
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompaniesList;
