import React, { useState } from "react";
import FAQSection from "./FAQSection";
import HiwSection from "./HiwSection";
import GetReferralBanner from "./GetReferralBanner";
import PartnerCompanies from "./PartnerCompanies";

const HeroSection = () => {
  return (
    <div className="px-[22rem]">
      <div class="flex-col items-center justify-center bg-[#F6F9FE]">
        <div class="font-google-sans font-bold text-center text-2xl sm:text-5xl md:text-2xl lg:text-7xl mx-42 !leading-tight">
          Request job referrals in seconds
        </div>
        <div className="hidden md:flex items-center space-x-3 py-10 justify-center">
          <button className="w-96 lg:w-[228px] bg-yellow opacity-80 text-black font-semibold px-4 py-2 rounded-full hover:opacity-100 transition-all duration-400 border-[1px] border-black	">
            Sign in
          </button>
          <button className="w-96 lg:w-[228px] bg-blue opacity-80 text-black font-semibold px-4 py-2 rounded-full hover:opacity-100 transition-all duration-200 border-[1px] border-black">
            Sign up
          </button>
        </div>
      </div>
      <PartnerCompanies />
      <HiwSection />
      <GetReferralBanner />
      <FAQSection />
    </div>
  );
};

export default HeroSection;
