import React from "react";

export default function Popup({ isOpen, onClose }) {
  if (!isOpen) return null; 

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[90%] sm:w-[70%] md:w-[50%] lg:w-[35%] p-6">
        <h2 className="text-xl sm:text-2xl font-semibold text-blue-600 mb-4 text-center">
          Verify your Account
        </h2>
        <p className="text-gray-600 text-sm sm:text-base mb-4 text-center">
          Please check your email for the OTP to complete verification.
        </p>
        <input
          type="text"
          placeholder="Enter OTP here"
          className="w-full border rounded-lg px-3 py-2 mb-4"
        />
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded-lg w-full hover:bg-blue-600 transition mb-2"
          onClick={onClose}
        >
          Verify
        </button>
        <p className="text-sm text-gray-500 text-center">
          Didn’t receive OTP?{" "}
          <button className="text-blue-500 underline">Request Again</button>
        </p>
      </div>
    </div>
  );
}
