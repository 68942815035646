import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../store/userSlice";
import HeroSection from "../components/global/HeroSection";

export default function LandingPage() {
  return (
    <div className="py-14">
      <UserProfile />
      <HeroSection />
    </div>
  );
}

const UserProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const handleLogin = () => {
    const fakeUserData = {
      userData: { name: "John Doe", email: "john@example.com" },
      authToken: "xyz",
    };
    dispatch(login(fakeUserData));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <h1>Welcome, {userData.name}</h1>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <button onClick={handleLogin}></button>
      )}
    </div>
  );
};
