import React, { useState } from "react";
import Tabs from "../global/Tabs";
import Table from "../global/Table";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(1);

  const companies = [
    { id: 1, name: "Google", status: "Pending", jobUrl: "http://google.com/jobs" },
    { id: 2, name: "Google", status: "Pending", jobUrl: "http://google.com/jobs" },
    { id: 3, name: "Amazon", status: "Accepted", jobUrl: "http://amazon.com/jobs" },
    { id: 4, name: "Amazon", status: "Accepted", jobUrl: "http://amazon.com/jobs" },
    { id: 5, name: "Microsoft", status: "Rejected", jobUrl: "http://microsoft.com/jobs" },
    { id: 6, name: "Microsoft", status: "Rejected", jobUrl: "http://microsoft.com/jobs" },
  ];

  return (
    <div className="flex flex-col items-center py-10 md:py-20 bg-blue-50">
      <Tabs onTabChange={setActiveTab} />
      <Table data={companies} activeTab={activeTab} />
    </div>
  );
};

export default Dashboard;
