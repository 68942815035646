import React, { useState } from "react";

const Tabs = ({ onTabChange }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    onTabChange(tabIndex);
  };

  return (
    <div className="w-3/4">
      {/* Dropdown for small screens */}
      <div className="block w-full md:hidden border border-black rounded-full px-4 bg-blue-500 text-white">
        <select
          className="w-full py-2 focus:outline-none bg-transparent"
          value={activeTab}
          onChange={(e) => handleTabClick(Number(e.target.value))}
        >
          <option value={1}>Pending Applications</option>
          <option value={2}>Accepted</option>
          <option value={3}>Rejected</option>
        </select>
      </div>

      {/* Tabs for large screens */}
      <div className="hidden md:flex justify-center rounded-full transition bg-white border border-black">
        <nav
          className="flex justify-center md:justify-evenly gap-x-1 w-full"
          aria-label="Tabs"
          role="tablist"
          aria-orientation="horizontal"
        >
          {["Pending Applications", "Accepted", "Rejected"].map(
            (tab, index) => (
              <button
                key={index}
                type="button"
                className={`md:py-3 md:px-4 inline-flex items-center gap-x-2 text-md font-medium rounded-full w-full justify-center focus:outline-none transition
                ${
                  activeTab === index + 1
                    ? "bg-blue-500 text-white"
                    : "text-black hover:text-gray-500"
                }`}
                aria-selected={activeTab === index + 1}
                aria-controls={`segment-${index + 1}`}
                role="tab"
                onClick={() => handleTabClick(index + 1)}
              >
                {tab}
              </button>
            )
          )}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
