import React, { useState } from "react";

const PartnerCompanies = () => {
  const images = [
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
    "GDG.png",
  ];

  return (
    <div className="py-24">
      <div className="flex items-center justify-center space-x-3">
        {/* Left SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/LeftVector.svg`}
          alt="Left Vector"
          className="w-8 h-auto"
        />

        {/* Heading Text */}
        <h2
          className="text-black text-3xl md:text-4xl font-bold"
          style={{
            fontFamily: "Google Sans, sans-serif",
            lineHeight: "1.2",
          }}
        >
          Partner Company’s
        </h2>

        {/* Right SVG */}
        <img
          src={`${process.env.PUBLIC_URL}/RightVector.svg`}
          alt="Right Vector"
          className="w-8 h-auto"
        />
      </div>
      <div className="flex flex-wrap justify-center gap-4 bg-bg1 px-4 pt-14 pb-4">
        {images.map((src, index) => (
          <div
            key={index}
            className="w-20 h-20 flex items-center justify-center border border-gray-300 rounded-md p-4"
          >
            <img
              src={src}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerCompanies;
